$('.required').click(function(){
  $(this).removeClass('error');
  $('.error-description').addClass('d-none');
});

$('.buy-ticket-hide').click(function(){
  $(this).removeClass('error');
  $('.error-description').addClass('d-none');
});

//Input text custom
$('.liberfly-input__text .form-control').focus(function(){

  $(this).parent().children('label').addClass('focus');
  $(this).addClass('focus');

});

$('.liberfly-input__text .form-control').focusout(function(){


  if($(this).val().length <= 0){
    $(this).parent().children('label').removeClass('focus');
    $(this).removeClass('focus');
  }
  
});
//End input text custom

//Input textarea custom
$('.liberfly-input__textarea .form-control').focus(function(){

  $(this).parent().removeClass('error');
  $(this).parent().children('label').addClass('focus');
  $(this).addClass('focus');

});

$('.liberfly-input__textarea .form-control').focusout(function(){

  if($(this).val().length <= 0){
    $(this).parent().children('label').removeClass('focus');
    $(this).removeClass('focus');
  }
  
});
//End input textarea custom

//Input select custom
$('.liberfly-input__select .select-custom').each(function(){
    var $this = $(this), numberOfOptions = $(this).children('option').length;
  
    $this.addClass('select-hidden'); 
    $this.wrap('<div class="select"></div>');
    $this.after('<div class="select-styled"></div>');

    var $styledSelect = $this.next('div.select-styled');
    $styledSelect.text($this.children('option').eq(0).text());
  
    var $list = $('<ul />', {
        'class': 'select-options'
    }).insertAfter($styledSelect);
  
    for (var i = 0; i < numberOfOptions; i++) {
        $('<li />', {
            text: $this.children('option').eq(i).text(),
            rel: $this.children('option').eq(i).val()
        }).appendTo($list);
    }
  
    var $listItems = $list.children('li');
  
    $styledSelect.click(function(e) {
        e.stopPropagation();

        $(this).parent().parent().removeClass('error');
        $('.error-description').addClass('d-none');

        $('div.select-styled.active').not(this).each(function(){
          $(this).removeClass('active').next('ul.select-options').hide();
        });
        $(this).toggleClass('active').next('ul.select-options').toggle();

    });
  
    $listItems.click(function(e) {
        e.stopPropagation();
        $styledSelect.text($(this).text()).removeClass('active');
        $this.val($(this).attr('rel'));
        $list.hide();
        

        if($this.val().includes('agência')){
          $('.'+$this.attr('id')+'-hide').removeClass('d-none');
          $('.'+$this.attr('id')+'-hide').addClass('required');
        }else{
          $('.'+$this.attr('id')+'-hide').addClass('d-none');
          $('.'+$this.attr('id')+'-hide').removeClass('required');
        }

        $list.parent().parent().addClass('selected');
    });
  
    $(document).click(function() {
        $styledSelect.removeClass('active');
        $list.hide();
    });

});
//End input select custom

//Start checkbox checked and childrens open
var cf_assunto = [];

$('.checkbox-input').click(function(){

  var id = $(this).attr('id');

  if($(this).is(':checked')){
    $('.'+id+'-hide').removeClass('d-none');
    $('.'+id+'-hide').addClass('required');

    cf_assunto.push($(this).attr('subject'));

    $('.'+id+'-hide select').each(function(){
      if(!cf_assunto.includes($(this).val())){
        cf_assunto.push($(this).val());
      }
    });
  }else{
    $('.'+id+'-hide').addClass('d-none');
    $('.'+id+'-hide').removeClass('required');
    cf_assunto.splice( $.inArray($(this).attr('subject'), cf_assunto), 1);

    $('.'+id+'-hide select').each(function(){
      if(cf_assunto.includes($(this).val())){
        cf_assunto.splice( $.inArray($(this).val(), cf_assunto), 1);
      }
    });
  }

})

$('.select-assunto li').click(function(){
  var subject = $(this).attr('rel');

  $(this).parent().children('li').each(function(index, el) {

    if(cf_assunto.includes($(this).attr('rel'))){
      cf_assunto.splice( $.inArray($(this).attr('rel'), cf_assunto), 1);
    }

  });

  cf_assunto.push(subject);
});

//End checkbox checked and childrens open

$('.custom-select2').change(function(){

  $(this).parent().addClass('selected');
  var customSelect = $(this).next();
  customSelect.children().children('.select2-selection--single').addClass('active');

});