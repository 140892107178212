//var url = "http://192.168.0.72/freshsales/form";
var url = "https://api.liberfly.com.br/freshsales/form";


$('#btn-next').click(function(){
	if (location.hostname !== "localhost"){
		validateTagManager();
	}

	if(emailValidated && nameValidated){
		sendAjax();
		if(step == totalSteps){
			$('.col-md-5').remove();
			$('.col-md-7').remove();
			$('#btn-prev').remove();
        	$('#btn-next').remove();
			$('.col-12').removeClass('d-none');
			$('.complaint-success').removeClass('d-none');
			$('.complaint-header .title').html('Reclamação enviada com sucesso <i class="fas fa-check"></i>');
			$('.complaint-header').addClass('success');
		}
	}
});

$('.step').click(function(){
	if (location.hostname !== "localhost"){
		validateTagManager();
	}
	if(emailValidated && nameValidated){
		sendAjax();
	}
});

function sendAjax(){
	$.ajax({
	    type: "POST",
	    url: url,
	    data: getParams(),
	    dataType: "JSON",
	    success: function(response) {
	    	//console.table(response);
			if (response.contact_id) {
				$('#contact_id').val(response.contact_id);
			}
			if (response.lead_id) {
				$('#lead_id').val(response.lead_id);
			}
			if (response.deal_id) {
				$('#deal_id').val(response.deal_id);
			}
			if (response.lead_is_converted) {
				$('#lead_is_converted').val(response.lead_is_converted);
			}
			if (response.disqualified) {
				$('#disqualified').val(response.disqualified);
			}
	    },
	    error: function(error) {
	        console.error(error);
	    }
	});
}