var nameValidated = false;
var emailValidated = false;

$('#btn-next').click(function(){
	validateForm();
});

$('.step').click(function(){
	validateLead();
});

function validateForm(){
	if(step == 3){
		$('.required').each(function(){
			var input = $(this).children().is('input.form-control');
			var inputValue = $(this).find('input.form-control').val();
			var inputNameValue = $(this).find('input#first_name').val();
			var inputEmailValue = $(this).find('input#email').val();
			var inputPhoneValue = $(this).find('input#mobile_number').val();
			var select = $(this).children().children().is('.select-custom');
			var selectValue = $(this).find('.select-custom').val();
			var selectSearch = $(this).children().is('.custom-select2');
			var selectSearchValue = $(this).find('.custom-select2').val();
			var checkbox = $(this).children().is('.checkbox-input');
			var checkboxValue = $(this).find('.checkbox-input').is(':checked');

			if(input){
				if(!inputValue){
					$(this).addClass('error');
					$('.error-description').removeClass('d-none');
				}else if(inputNameValue){
					if(inputNameValue.trim().split(' ').length <= 1){
						$(this).addClass('error');
						$('.error-description').removeClass('d-none');
					}
				}else if(inputEmailValue){
					if(!validateEmail(inputEmailValue)){
						$(this).addClass('error');
						$('.error-description').removeClass('d-none');
					}
				}else if(inputPhoneValue){
			      	if(inputPhoneValue.length < 6){
						$(this).addClass('error');
						$('.error-description').removeClass('d-none');
					}
				}
			}else if(select){
				if(selectValue == 'hide'){
					$(this).addClass('error');
					$('.error-description').removeClass('d-none');
				}
			}else if(selectSearch){
				if(!selectSearchValue){
					$(this).addClass('error');
					$('.error-description').removeClass('d-none');
				}
			}else if(checkbox){
				if(!checkboxValue){
					$(this).addClass('error');
					$('.error-description').removeClass('d-none');
				}
			}
		});

		var errorList = [];

		$('.page').each(function(index, value){

			var troubleCheck = [];
			var totalChecks = 0;

			if($(this).find('.required').hasClass('error')){
				errorList.push(index);
			}

			if($(this).hasClass('page-trouble')){

				$(this).find('.liberfly-input__checkbox').each(function(index, value) {
					if(!$(value).find('.checkbox-input').is(':checked')){
						troubleCheck.push(index);
					}

					totalChecks++;
				});

				if(troubleCheck.length == totalChecks){
					errorList.push(index);
				}
			}

		});

		if(errorList.length > 0){
			step = errorList[0] - 1;

			$('.step').each(function(index, value){

			    if(step == index){
			      $(value).removeClass('complete');
			      $(value).addClass('active');
			    }else if(step < index){
			      $(this).removeClass('active');
			      $(this).removeClass('complete');
			    }

			});
		}
		
	}

	var nameValue = $('input#first_name').val();
	var emailValue = $('input#email').val();

	if(nameValue){
		if(nameValue.trim().split(' ').length <= 1){
			nameValidated = false;
		}else{
			nameValidated = true;
		}
	}
	
	if(emailValue){
		if(!validateEmail(emailValue)){
			emailValidated = false;
		}else{
			emailValidated = true;
		}
	}
}

function validateLead(){
	var nameValue = $('input#first_name').val();
	var emailValue = $('input#email').val();

	if(nameValue){
		if(nameValue.trim().split(' ').length <= 1){
			nameValidated = false;
		}else{
			nameValidated = true;
		}
	}
	
	if(emailValue){
		if(!validateEmail(emailValue)){
			emailValidated = false;
		}else{
			emailValidated = true;
		}
	}
}

function validateDeal(){

	var canValidate = true;

	$('.page-trouble').find('.liberfly-input__checkbox').each(function(index, value) {
		if($(value).find('.checkbox-input').is(':checked')){
			var checkedId = $(value).find('.checkbox-input').attr('id');
			if(checkedId != 'atraso' && checkedId != 'cancelamento'){
				canValidate = false;
			}
		}
	});

	if(canValidate){
		var delayTime = $('#delay_time').val();
		var cancelDelay = $('#cancel_delay').val();
		if($('#atraso').is(':checked') && $('#cancelamento').is(':checked')){
			if(delayTime == 3 && cancelDelay == 'Menos de 4 horas de atraso/adiantamento no destino final;'){
				$('.complaint-error .error').html('<p><i class="fas fa-chevron-right"></i> Menos de 4 horas de atraso no voo</p><p><i class="fas fa-chevron-right"></i> Menos de 4 horas de atraso/adiantamento no destino final após o cancelamento</p>');
				return false;
			}
		}else if($('#atraso').is(':checked')){
			if(delayTime == 3){
				$('.complaint-error .error').html('<p><i class="fas fa-chevron-right"></i> Menos de 4 horas de atraso no voo</p>');
				return false;
			}
		}
		else if($('#cancelamento').is(':checked')){
			if(cancelDelay == 'Menos de 4 horas de atraso/adiantamento no destino final;'){
				$('.complaint-error .error').html('<p><i class="fas fa-chevron-right"></i> Menos de 4 horas de atraso/adiantamento no destino final após o cancelamento</p>');
				return false;
			}
		}
	}
	
	return true;
}