function replaceAll(str, find, replace) {
    return str.replace(new RegExp(find, 'g'), replace);
}

function getParams(){

	var firstName = '';
	var lastName = '';
	var fullName = $('#first_name').val();

	var stepDb = step + 8000117377;

	$('#cf_etapa_no_formulario').val(stepDb);
	// $('#lead_stage_id').val(stepDb);

	var splitName = fullName.split(' ');

	$(splitName).each(function(index, value){
		if(index == 0){
			firstName = value;
		}else{
			lastName = lastName + value + ' ';
		}
	});

	var params = $("#wrapped").serialize();

  fullName = replaceAll(fullName, ' ', '%20');
	lastName = replaceAll(lastName, ' ', '%20');

	params = params.replace(fullName, firstName);

	params = params + '&last_name=' + lastName;

	if($(cf_assunto.length > 1)){
		$(cf_assunto).each(function(index,value){

			if(value && value != 'hide'){
				params = params + '&cf_assunto%5B%5D=' + encodeURI(value);
			}
			
		});
	}

	if(!params.includes('cf_permissao_para_whatsapp')){
		params = params.concat('&cf_permissao_para_whatsapp=Recusada');
	}

  params = replaceAll(params, 'hide', '');

	return params;

}

function validateEmail(mailText){
  var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if(mailText.match(mailformat)){
    return true;
  }else{
    return false;
  }
}

//Pages navigation
var step = 0;
var totalSteps = $('.page').length;

$('#btn-next').click(function(){

  step += 1;
  $('#btn-prev').removeClass('d-none');

  $('.page').each(function(index, value){

    if(step == index){
      $(value).removeClass('d-none');
    }else{
      $(value).addClass('d-none');
    }

  });

  $('.step').each(function(index, value){

    if(step == index){
      $(value).addClass('active');
    }else if(step > index){
      $(this).removeClass('active');
      $(this).addClass('complete');
    }

  });

  if(step == 0){
    $('#btn-prev').addClass('d-none');
  }

});

$('#btn-prev').click(function(){

  step -= 1;

  if(step == 0){
    $('#btn-prev').addClass('d-none');
  }

  $('.page').each(function(index, value){

    if(step == index){
      $(value).removeClass('d-none');
    }else{
      $(value).addClass('d-none');
    }

  });

  $('.step').each(function(index, value){

    if(step == index){
      $(value).removeClass('complete');
      $(value).addClass('active');
    }else if(step < index){
      $(this).removeClass('active');
      $(this).removeClass('complete');
    }

  });

});

$('.step').click(function(){

  step = $(this).children('.step-header').children('.number').html();
  step = step - 1;

  if(step == 0){
    $('#btn-prev').addClass('d-none');
    $('#btn-next').removeClass('d-none');
  }else{
    $('#btn-prev').removeClass('d-none');
    $('#btn-next').removeClass('d-none');
  }

  $('.page').each(function(index, value){

    if(step == index){
      $(value).removeClass('d-none');
    }else{
      $(value).addClass('d-none');
    }

  });

  $('.step').each(function(index, value){

    if(step == index){
      $(value).removeClass('complete');
      $(value).addClass('active');
    }else if(step < index){
      $(this).removeClass('active');
      $(this).removeClass('complete');
    }else{
      $(this).removeClass('active');
      $(this).addClass('complete');
    }

  });


});
//End pages navigation

//Start custom mask for phone input
$('.phone-block').css('display', 'none');
$('.liberfly-phone .form-control').mask('+00000000000000000000');
// $('.liberfly-phone .form-control').mask('+00 (00) 00000-0000');

// var phoneArea = $('.phone-block .radio-input').val();

// $('.phone-block .radio-input').change(function(){

//   phoneArea = $(this).val();

//   $('#mobile_number').parent().children('label').removeClass('focus');
//   $('#mobile_number').removeClass('focus');
//   $('#mobile_number').val('');

//   $('.liberfly-phone .form-control').mask('+00 (00) 00000-0000');

//   if($(this).val() == 'Europa'){
//     $('.liberfly-phone .form-control').mask('+00000000000000000000');
//   }

// });
// End custom mask for phone input

// $('#mobile_number').focus(function(){
//   if(!$('#mobile_number').val()){
//     if(phoneArea == 'Brasil'){
//       $(this).val('+55');
//     }
//   }
// });

(function($) {

  var Defaults = $.fn.select2.amd.require('select2/defaults');
  
  $.extend(Defaults.defaults, {
    dropdownPosition: 'auto'
  });
  
  var AttachBody = $.fn.select2.amd.require('select2/dropdown/attachBody');
  
  var _positionDropdown = AttachBody.prototype._positionDropdown;
  
  AttachBody.prototype._positionDropdown = function() {
   
    var $window = $(window);
   
    var isCurrentlyAbove = this.$dropdown.hasClass('select2-dropdown--above');
    var isCurrentlyBelow = this.$dropdown.hasClass('select2-dropdown--below');
   
    var newDirection = null;
   
    var offset = this.$container.offset();
   
    offset.bottom = offset.top + this.$container.outerHeight(false);
    
    var container = {
        height: this.$container.outerHeight(false)
    };
    
    container.top = offset.top;
    container.bottom = offset.top + container.height;

    var dropdown = {
      height: this.$dropdown.outerHeight(false)
    };

    var viewport = {
      top: $window.scrollTop(),
      bottom: $window.scrollTop() + $window.height()
    };

    var enoughRoomAbove = viewport.top < (offset.top - dropdown.height);
    var enoughRoomBelow = viewport.bottom > (offset.bottom + dropdown.height);
    
    var css = {
      left: offset.left,
      top: container.bottom
    };

    // Determine what the parent element is to use for calciulating the offset
    var $offsetParent = this.$dropdownParent;

    // For statically positoned elements, we need to get the element
    // that is determining the offset
    if ($offsetParent.css('position') === 'static') {
      $offsetParent = $offsetParent.offsetParent();
    }

    var parentOffset = $offsetParent.offset();

    css.top -= parentOffset.top
    css.left -= parentOffset.left;
    
    var dropdownPositionOption = this.options.get('dropdownPosition');
    
    if (dropdownPositionOption === 'above' || dropdownPositionOption === 'below') {
    
        newDirection = dropdownPositionOption;
    
    } else {
        
        if (!isCurrentlyAbove && !isCurrentlyBelow) {
            newDirection = 'below';
        }

        if (!enoughRoomBelow && enoughRoomAbove && !isCurrentlyAbove) {
          newDirection = 'above';
        } else if (!enoughRoomAbove && enoughRoomBelow && isCurrentlyAbove) {
          newDirection = 'below';
        }
    
    }

    if (newDirection == 'above' ||
        (isCurrentlyAbove && newDirection !== 'below')) {
      css.top = container.top - parentOffset.top - dropdown.height;
    }

    if (newDirection != null) {
      this.$dropdown
        .removeClass('select2-dropdown--below select2-dropdown--above')
        .addClass('select2-dropdown--' + newDirection);
      this.$container
        .removeClass('select2-container--below select2-container--above')
        .addClass('select2-container--' + newDirection);
    }

    this.$dropdownContainer.css(css);
   
  };
  
})(window.jQuery);

//Start custom selectsearch
$('.liberfly-input__select #envolved-company').select2({
  placeholder: 'Companhia aérea envolvida',
  dropdownPosition: 'below'
});

$('.liberfly-input__select #envolved-agency').select2({
  placeholder: 'Seleciona a agência',
  dropdownPosition: 'below'
});
//End placeholder from selectsearch
